<template>
  <div class="settings__pages">
    <v-row>
      <v-col cols="12">
        <h2>Settings</h2>
        <div>
          Expanding new sales channels helps you sell to new customers online, on mobile apps, through social networks,
          and in person.
        </div>
      </v-col>
      <v-col v-for="(page, index) in pages" :key="index" cols="12" md="4" sm="12" xs="12">
        <router-link
          class="text-decoration-none"
          :to="{
            name:
              page.router !== 'website.settings.payments' || utilities.checkPermisson(`payment_providers`, user)
                ? page.router
                : 'no-access',
          }"
        >
          <v-hover v-slot="{ hover }" open-delay="100">
            <v-card
              height="125"
              :elevation="hover ? 8 : 1"
              :class="{ 'on-hover': hover }"
              class="mx-auto card__background"
            >
              <v-card-text>
                <v-row no-gutters>
                  <v-col cols="3" xs="3" sm="3" md="3" class="d-flex align-center">
                    <v-img max-width="48px" :src="page.icon" :alt="page.des" />
                  </v-col>
                  <v-col cols="9" xs="9" sm="9" md="9">
                    <h3 class="text-decoration-none">{{ page.title }}</h3>
                    <p class="text-decoration-none mt-3">{{ page.des }}</p>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-hover>
        </router-link>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import utilities from '@/helpers/utilities';

export default {
  computed: {
    ...mapGetters(['user']),
  },
  data() {
    return {
      utilities,
      pages: [
        {
          icon: require('@/assets/images/sale-channels/google.svg'),
          title: 'Google',
          des: `Reach shoppers and get discovered across Google`,
          router: 'website.settings.sale-channels.google',
        },
        {
          icon: require('@/assets/images/sale-channels/ggads.svg'),
          title: 'Google Ads',
          des: `Reach new customers and grow your business with Google Ads`,
          router: 'website.settings.sale-channels.ggads',
        },
        {
          icon: require('@/assets/images/sale-channels/facebook.svg'),
          title: 'Facebook Data Assistant',
          des: 'View data in Facebook Ads more timely and accurately',
          router: 'website.settings.sale-channels.facebook',
        },
        // {
        //   icon: require('@/assets/images/sale-channels/esty.png'),
        //   title: 'Esty',
        //   des: 'View data in Facebook Ads more timely and accurately',
        //   router: 'website.settings.checkout',
        // },
        // {
        //   icon: require('@/assets/images/sale-channels/pinterest.png'),
        //   title: 'Pinterest',
        //   des: 'Track actions people take on your website via Pinterest.',
        //   router: 'website.settings.sale-channels.pinterest',
        // },
        {
          icon: require('@/assets/images/sale-channels/tiktok.png'),
          title: 'Tiktok',
          des: 'Find new customers and optimize your ads campaigns.',
          router: 'website.settings.sale-channels.tiktok',
        },
        // {
        //   icon: require('@/assets/images/sale-channels/bingads.png'),
        //   title: 'Bing Ads',
        //   des: 'Understand more about customer behavior and the traffic source.',
        //   router: 'website.settings.sale-channels.bingads',
        // },
        // {
        //   icon: require('@/assets/images/sale-channels/criteo.png'),
        //   title: 'Criteo',
        //   des: 'Measure and understand actions that people take on your website.',
        //   router: 'website.settings.sale-channels.criteo',
        // },
        {
          icon: require('@/assets/images/sale-channels/klaviyo.webp'),
          title: 'Klaviyo',
          des: 'Klavyio intergration.',
          router: 'website.settings.sale-channels.klaviyo',
        },
        // {
        //   icon: require('@/assets/images/sale-channels/trustPilot.webp'),
        //   title: 'Trust Pilot',
        //   des: 'Trust Pilot Review.',
        //   router: 'website.settings.sale-channels.trustPilot',
        // },
      ],
    };
  },
};
</script>
<style scoped lang="scss">
.card__background {
  background-color: #efefef;
  &:hover {
    background-color: white;
  }
  transition: background 0.2s;
}
</style>
